import Form from "@/Components/Form";
import TextField from "@/Components/TextField";
import Button from "@/Components/Button";
import GuestLayout from "@/Layouts/GuestLayout";
import { Strings } from "@/resources";
import AppHead from "@/Components/AppHead";
import { useEffect, useState } from "react";
import { checkEmail, resendInvite } from "@/services/account";
import { toast } from "react-toastify";
import { router } from "@inertiajs/react";

export default function Home({
    status,
    ziggy,
    app_logo,
    clinic,
}: {
    status?: string;
    ziggy: any;
    app_logo?: string;
    clinic?: any;
}) {
    const [email, setEmail] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isAccountModalVisible, setIsAccountModalVisible] =
        useState<boolean>(false);

    useEffect(() => {
        initializeData();
    }, []);

    const initializeData = () => {
        const queryString = window.location.search;
        const searchParams = new URLSearchParams(queryString);
        const email = searchParams.get("email");

        if (email) {
            setEmail(email);
            shouldInviteModalBeVisible();
        }
    };

    const shouldInviteModalBeVisible = () => {
        const queryString = window.location.search;
        const searchParams = new URLSearchParams(queryString);
        const showInviteModal = searchParams.get("showInviteModal");

        if (showInviteModal && showInviteModal === "true") {
            setIsAccountModalVisible(true);
        }
    };

    const submit = async () => {
        setIsLoading(true);
        const { error, status, url } = await checkEmail(ziggy.clinic, email);
        setIsLoading(false);

        if (error) {
            toast.error(error);
            return;
        } else if (status === "DISABLED") {
            toast.error(Strings.start_screen.account_disabled_error);
            return;
        } else if (status === "ACTIVE") {
            router.get(route("login", { clinic: ziggy.clinic, email }));
            return;
        } else if (status === "NOT_AVAILABLE") {
            router.get(route("signup", { clinic: ziggy.clinic, email }));
            return;
        } else if (status === "INACTIVE") {
            if (url !== ziggy.clinic) {
                window.location.replace(
                    route("home", {
                        clinic: url,
                        email: email,
                        showInviteModal: "true",
                    }),
                );
            } else setIsAccountModalVisible(true);
        }
    };

    const userIcon = (
        <span className="svg-img-replacer left-icon">
            <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_1_12122)">
                    <path
                        d="M12 11.1719C14.2091 11.1719 16 9.38101 16 7.17188C16 4.96274 14.2091 3.17188 12 3.17188C9.79086 3.17188 8 4.96274 8 7.17188C8 9.38101 9.79086 11.1719 12 11.1719Z"
                        stroke="var(--secondary-color)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M6 21.1719V19.1719C6 18.111 6.42143 17.0936 7.17157 16.3434C7.92172 15.5933 8.93913 15.1719 10 15.1719H14C15.0609 15.1719 16.0783 15.5933 16.8284 16.3434C17.5786 17.0936 18 18.111 18 19.1719V21.1719"
                        stroke="var(--primary-color)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1_12122">
                        <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="translate(0 0.171875)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </span>
    );

    const resendInviteEmail = async () => {
        setIsLoading(true);
        const { error } = await resendInvite(ziggy.clinic, email);
        setIsLoading(false);

        if (error) {
            toast.error(error);
            return;
        } else {
            toast.success(Strings.start_screen.invite_sent);
            setIsAccountModalVisible(false);
        }
    };

    const renderAccountInviteModal = () => {
        if (!isAccountModalVisible) return null;

        return (
            <div className="flashcard-container show">
                <div className="flash-card email-verification-modal">
                    <a
                        href="#"
                        className="close-btn"
                        onClick={() => setIsAccountModalVisible(false)}
                    >
                        {" "}
                    </a>
                    <div className="card-container">
                        <div className="card-content">
                            <h5>{Strings.invite_modal.heading}</h5>
                            <p>{Strings.invite_modal.sub_heading}</p>
                            <div className="btn-items">
                                <Button
                                    disabled={isLoading}
                                    loading={isLoading}
                                    onClick={() => resendInviteEmail()}
                                >
                                    {Strings.invite_modal.btn_text}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <GuestLayout ziggy={ziggy}>
            <main className="signup form-container">
                <AppHead clinic={clinic} title={Strings.start_screen.title} />

                <Form
                    onSubmit={submit}
                    status={status}
                    title={Strings.start_screen.heading}
                    app_logo={app_logo}
                    className="enter-email-form"
                    ziggy={ziggy}
                >
                    <div className="input-container">
                        <div className="input-fieldContent">
                            <TextField
                                type="email"
                                name="email"
                                value={email}
                                placeholder={
                                    Strings.start_screen.email_placeholder
                                }
                                isFocused={true}
                                leftIcon={userIcon}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                ) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="form-btn">
                        <Button disabled={isLoading} loading={isLoading}>
                            <p>{Strings.start_screen.btn_text}</p>
                        </Button>
                    </div>
                </Form>

                {renderAccountInviteModal()}
            </main>
        </GuestLayout>
    );
}
